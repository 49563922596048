import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import image from "../../content/assets/404.png"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <div className="text-center mt-24">
          <div className="w-full mx-auto">
            <img className="w-64 block mx-auto" src={image} alt="404"></img>
          </div>
          <h1 className="mt-4 text-2xl">This link doesn't exist</h1>
          <p className="mt-2">Maybe try these other links:</p>
          <div className="mt-4">
            <Link
              className="block text-blue-400 font-bold italic underline"
              to="/blog"
            >
              Writing
            </Link>
            <a
              className="text-blue-400 font-bold italic underline"
              href="https://www.notion.so/Read-ing-List-96d87fa62d584af2bf68ea5bc9c6e215"
            >
              Read[ing] List
            </a>
            <p className="text-blue-400 font-bold italic underline">
              My{" "}
              <a href="https://www.notion.so/Public-Commonplace-Book-93a46459b77c4a6fa5d23bbfa1e97957">
                Commonplace
              </a>{" "}
              Book
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
